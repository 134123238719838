<template>
  <div class="py-2xl bg-white" data-test-id="logoCarousel">
    <Heading
      size="md"
      weight="semibold"
      class="ui-text-center mb-xl text-charcoal-default px-sm"
      data-test-id="LogoCarouselTitle"
      >{{ title }}</Heading
    >
    <LazySlider
      v-if="logos?.length > 0"
      :slides-per-view="2.5"
      :space-between="24"
      :draggable="true"
      :autoplay="false"
      :total-slides="logos.length"
      :items="logos"
      :breakpoints="{
        450: { slidesPerView: 3.5 },
        600: { slidesPerView: 4.5 },
        850: { slidesPerView: 5.5 },
        1000: { slidesPerView: 7 },
      }"
    >
      <swiper-slide
        v-for="(logo, index) in logos"
        :key="index"
        :data-test-id="`LogoCarouselItem-${index}`"
        class="m-auto"
      >
        <div v-if="logo.link.target != '_blank'">
          <NuxtLink v-if="logo.link.href" :to="logo.link.href" :target="logo.link.target">
            <NuxtPicture :src="logo.image" loading="lazy" :alt="logo.alt" />
          </NuxtLink>
          <NuxtPicture
            v-else
            :src="logo.image"
            loading="lazy"
            :alt="logo.alt"
            :img-attrs="{ class: 'min-w-[100px] mx-auto' }"
          />
        </div>
        <div v-else>
          <a v-if="logo.link.href" :href="logo.link.href" :target="logo.link.target" :key="index">
            <NuxtPicture :src="logo.image" loading="lazy" :alt="logo.alt" />
          </a>
          <NuxtPicture v-else :src="logo.image" loading="lazy" :alt="logo.alt" />
        </div>
      </swiper-slide>
    </LazySlider>
  </div>
</template>

<script lang="ts" setup>
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useLogoCarousel } from "../../composables/UseContent/useLogoCarousel";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const carousel = computed(() => useLogoCarousel(page.value, component.value));

const title = computed(() => carousel.value.title);
const logos = computed(() => carousel.value.logos);
</script>
