import { Page, Component } from "@bloomreach/spa-sdk";
import { getFileName } from "mkm-avengers";

export function useLogoCarousel(page: Page, component: Component) {
  const { data, getSrcOf, getLinkOf } = useContentHelpers(page, component);

  return {
    title: data?.title,
    logos: data?.logos?.map(({ image, link: linkRef }: any) => {
      const imageSrc = getSrcOf(image);
      return {
        image: imageSrc,
        link: getLinkOf(linkRef),
        alt: getFileName(imageSrc),
      };
    }),
  };
}
